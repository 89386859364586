<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-14 15:56:16
 * @LastEditTime: 2022-07-14 16:24:46
 * @LastEditors: cyy
 * @Description: 优惠券推广
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\extension.vue
-->
<template>
  <div class="extension">
    <el-dialog
      title="推广"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="code">
          <div class="ewm  " id="qrcode"></div>
          <el-button type="text" class="mt10" @click="downEwm">
            下载二维码
          </el-button>
        </div>
        <div class="url">
          <div class="contain">
            {{ selectrow.url }}
          </div>

          <el-button
            type="text"
            class="mt10 for-copy"
            :data-clipboard-text="selectrow.url"
          >
            复制链接
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'extension',
  data() {
    return {
      dialogVisible: false,
    }
  },

  props: {
    selectrow: {
      type: Object,
      default: () => {},
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initCopy()

      this.create_ewm()
    })
  },

  methods: {
    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },

    // 生成二维码
    create_ewm() {
      const size = 155
      //生成前先清空原有的，不然会有多张二维码的情况
      document.getElementById('qrcode').innerHTML = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.selectrow.url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.extension {
    
  .dialogVisible {
    ::v-deep .el-dialog {
      height: 416px;
    }
    ::v-deep .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    ::v-deep .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      .code {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ewm {
          width: 155px;
          height: 155px;
        }
      }
      .url {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40px;
        .contain {
          box-sizing: border-box;
          width: 370px;
          height: 155px;
          background: #f5f5f5;
          border-radius: 6px;
          padding: 20px;
        }
      }
    }
  }
}
</style>
