<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-12 14:35:13
 * @LastEditTime: 2022-07-29 15:01:48
 * @LastEditors: cyy
 * @Description: 优惠券
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\main.vue
-->
<template>
  <div class="coupon">
    <div
      v-if="$route.name == 'coupon'"
      style="background-color: #fff;padding:20px"
    >
      <div class="search">
        <el-button
          type="primary"
          size="medium"
          @click="
            $router.push({ path: '/coupon/newCoupon/', query: { id: 'new' } })
          "
        >
          创建优惠券
        </el-button>
        <div class="select">
          <span class="fs12">类型：</span>
          <el-select
            v-model="post.type"
            size="medium"
            class="mr10"
            style="width:120px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <span class="fs12">优惠方式：</span>
          <el-select
            v-model="post.pre_way"
            size="medium"
            class="mr10"
            style="width:140px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <span class="fs12">叠加使用：</span>
          <el-select
            v-model="post.is_stackable"
            size="medium"
            class="mr10"
            style="width:120px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <span class="fs12">状态：</span>
          <el-select
            v-model="post.status"
            size="medium"
            class="mr10"
            style="width:120px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-input
            style="width:218px"
            v-model="post.search"
            class="mr20"
            @keyup.enter.native="search"
            size="medium"
            placeholder="输入名称搜索"
          ></el-input>

          <el-button type="primary" @click="search" size="medium">
            搜索
          </el-button>
        </div>
      </div>

      <pagination2
        class="mt30 childDialogBox"
        :option="post2"
        url="/coupon/couponList"
        ref="childDialogBox"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
          >
            <el-table-column
              label="优惠券名称"
              min-width="180px"
              fixed
              prop="name"
            >
              <template slot-scope="{ row }">
                <div class="hidetext" :title="row.name">{{ row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="{ row }">
                {{ row.type == 1 ? '通用券' : '专属券' }}
              </template>
            </el-table-column>
            <el-table-column label="优惠方式">
              <template slot-scope="{ row }">
                {{ row.pre_way == 1 ? '满减' : '折扣' }}
              </template>
            </el-table-column>
            <el-table-column label="优惠内容" width="140px">
              <template slot-scope="{ row }">
                {{ row.threshold == 0 ? '无门槛' : `满${row.threshold}元` }},
                {{
                  row.pre_way == 1
                    ? `减${row.cou_price}元`
                    : `折${row.discount}`
                }}
              </template>
            </el-table-column>
            <el-table-column label="叠加使用">
              <template slot-scope="{ row }">
                <span v-if="row.is_stackable == 1" style="color:#3D80EF">
                  允许
                </span>
                <span v-else style="color:#FF3535">不允许</span>
              </template>
            </el-table-column>
            <el-table-column label="已领取/已使用" min-width="120px">
              <template slot-scope="{ row }">
                {{ row.received_count }}/{{ row.used_count }}
              </template>
            </el-table-column>
            <el-table-column
              label="发放数量"
              prop="count_all"
            ></el-table-column>
            <el-table-column
              label="定向发放"
              prop="provide_count"
            ></el-table-column>
            <el-table-column label="总量">
              <template slot-scope="{ row }">
                {{ row.count_all + row.provide_count }}
              </template>
            </el-table-column>
            <el-table-column
              label="可领取用户身份"
              min-width="120px"
              prop="identity"
            >
              <template slot-scope="{ row }">
                <div
                  class="hidetext2"
                  :title="row.identity ? row.identity : '-'"
                >
                  {{ row.identity || '-' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1">未开始</span>
                <span v-if="row.status == 2" style="color:rgb(61, 128, 239)">
                  进行中
                </span>
                <span v-if="row.status == 3" style="color:red">已失效</span>
                <span v-if="row.status == 4" style="color:red">已结束</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="350px"
              fixed="right"
              align="right"
            >
              <!-- 定向发放  |  效果数据  |  编辑  |  停止  |  推广  |  复制  |  删除 -->
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: `/coupon/target/${row.coupon_id}`,
                      })
                    "
                    v-if="row.type != 2 && (row.status == 1 || row.status == 2)"
                  >
                    定向发放
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="row.type != 2 && (row.status == 1 || row.status == 2)"
                  ></el-divider>
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: `/coupon/dataeffect/${row.coupon_id}`,
                      })
                    "
                  >
                    效果数据
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: `/coupon/newCoupon`,
                        query: { id: row.coupon_id, type: 1 },
                      })
                    "
                  >
                    编辑
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="row.status == 1 || row.status == 2"
                  ></el-divider>
                  <el-button
                    type="text"
                    v-if="row.status == 1 || row.status == 2"
                    @click="couponStop(row)"
                  >
                    停止
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="row.status == 1 || row.status == 2"
                  ></el-divider>
                  <el-button
                    type="text"
                    v-if="row.status == 1 || row.status == 2"
                    @click="tuiguang(row)"
                  >
                    推广
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: `/coupon/newCoupon`,
                        query: { id: row.coupon_id, type: 2 },
                      })
                    "
                  >
                    复制
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="row.status == 3"
                  ></el-divider>
                  <el-button
                    type="text"
                    @click="del(row)"
                    v-if="row.status == 3"
                  >
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>

    <!-- 推广 -->
    <extension
      v-if="status"
      :selectrow="selectrow"
      :dialogstatus.sync="status"
    ></extension>

    <router-view></router-view>
  </div>
</template>

<script>
import extension from './components/extension'
import background from '@/mixin/background'
export default {
  name: 'coupon',

  mixins: [background],

  components: {
    extension,
  },

  data() {
    return {
      // 推广当前别选中的数据
      selectrow: {},

      status: false,

      post: {
        type: 0,
        pre_way: 0,
        is_stackable: 0,
        search: '',
        status: 0,
      },

      post2: {},

      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '通用券',
        },
        {
          value: 2,
          label: '专属券',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '满减',
        },
        {
          value: 2,
          label: '折扣',
        },
      ],
      options3: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '允许',
        },
        {
          value: 2,
          label: '不允许',
        },
      ],
      options4: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '未开始',
        },
        {
          value: 2,
          label: '进行中',
        },
        {
          value: 3,
          label: '已失效',
        },
        {
          value: 4,
          label: '已结束',
        },
      ],
    }
  },

  methods: {
    // 推广
    tuiguang(row) {
      this.selectrow = row

      this.$nextTick(() => {
        this.status = true
      })
    },

    // 停止发放
    async couponStop(row) {
      this.$confirm(
        '停止发券后，已领取/已发放的优惠券，在可用的时间内容还能继续使用，但无法再编辑优惠券内容。确定停止发券？',
        '停止发券',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/coupon/couponStop',
            data: { coupon_id: row.coupon_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 删除
    async del(row) {
      this.$confirm('是否确定删除？', '删除优惠券', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/coupon/couponDel',
            data: { coupon_id: row.coupon_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    // 搜索
    search() {
      this.post2 = _.assign({}, this.post)
      this.$refs.childDialogBox.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon {
  margin: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .childDialogBox {
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .el-divider {
        background-color: #0aa29b;
      }
    }
  }
}
</style>
